import Grid from "@mui/material/Grid";
import styled from "@mui/material/styles/styled";
import Button from "@mui/material/Button";
import DrawerViewImage from "../Drawer/ViewImage";
import { useState } from "react";
import { ModalContext } from "../../contexts/model-context";
import useModal from "../../useContexts/useModal";

const FlieTable = props => {
  const { files } = props;
  const [file, setFile] = useState({});
  const useModalViewFile = useModal();

  const onClickView = data => {
    setFile(data);
    useModalViewFile.handleOpen();
  };
  
  return (
    <Container>
      {files.map(file => (
        <RowDetail key={file.filename} container spacing={0}>
          <Grid item xs={2}>
            <img
              alt="file_other"
              src={getImageType(file.fileExtension) || "/assets/file_other.svg"}
              width=""
              height=""
            />
          </Grid>
          <GridFlexCenter item xs={8}>
            <div>{file.filename}</div>
          </GridFlexCenter>
          <Grid item xs={2}>
            <BootstrapButton
              onClick={() => onClickView(file)}
              variant="contained"
            >
              View
            </BootstrapButton>
          </Grid>
        </RowDetail>
      ))}
      <ModalContext.Provider value={useModalViewFile}>
        <DrawerViewImage file={file} />
      </ModalContext.Provider>
    </Container>
  );
};

const Container = styled("div")({
  paddingTop: "24px"
});

const GridFlexCenter = styled(Grid)({
  display: "flex",
  alignItems: "center"
});

const RowDetail = styled(Grid)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  },
  margin: "0px",
  padding: "16px"
}));

const BootstrapButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  borderRadius: "8px",
  lineHeight: 1.5,
  backgroundColor: "#545454",
  borderColor: "#0063cc",

  "&:hover": {
    backgroundColor: "#545454"
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)"
  }
});

const getImageType = fileExtension => {
  let imageType = "";
  fileExtension = fileExtension.toLowerCase();
  switch (fileExtension) {
    case "doc":
      imageType = "/assets/doc.svg";
      break;
    case "jpg":
      imageType = "/assets/jpg.svg";
      break;
    case "pdf":
      imageType = "/assets/pdf.svg";
      break;
    case "ppt":
      imageType = "/assets/ppt.svg";
      break;
    case "txt":
      imageType = "/assets/txt.svg";
      break;
    case "xls":
      imageType = "/assets/xls.svg";
      break;
    default:
      imageType = "/assets/file_other.svg";

      break;
  }
  return imageType;
};

FlieTable.defaultProps = {
  files: []
};
export default FlieTable;
