import axios from "axios";
import cookie from "js-cookie";
// NOTE: remove after auth api.

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_SERVICE,
  timeout: 120000
});
instance.interceptors.request.use(function(config) {
  const AUTH_TOKEN = `Bearer ${cookie.get("token")}`;
  config.headers.Authorization = AUTH_TOKEN;
  // instance.defaults.headers.common["Authorization"] = AUTH_TOKEN;
  return config;
});

export default instance;
