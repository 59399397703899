import callApi from "../../lib/fetch";
import axios from "axios";
import oauth from "axios-oauth-client";

const login = emailAndPassword => {
  // console.log(emailAndPassword);
  // const getAuthorizationCode = oauth
  //   .client(axios.create(), {
  //     ...emailAndPassword,
  //     username: emailAndPassword.userName,
  //     grant_type: "password_credential",
  //     client_id: "d66ff6f5-3ebd-450e-810d-ff1fde42cc15",
  //     client_secret: "RRN7Q~Zbt46rfUdi3pFMPv7rJoXO.nggEii_V",
  //     redirect_uri:
  //       "https://login.microsoftonline.com/dd7f2fa8-8e15-4aa2-b4f9-9275116abf30/oauth2/v2.0/token",
  //     scope: "https://graph.microsoft.com/.default"
  //   })
  // // debugger;
  // // console.log(getAuthorizationCode)
  // getAuthorizationCode()
  //   .then(response => {
  //     console.log(response);
  //   })
  //   .catch(err => {
  //     console.log(err);
  //   });
  return new Promise((resolve, reject) => {
    callApi
      .post("/service/login", emailAndPassword)
      .then(response => {
        resolve(response.data.responseData[0]);
      })
      .catch(error => {
        reject(error);
      })
      .finally(() => {});
  });
};

export { login };
