import React, { useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FlakyIcon from "@mui/icons-material/Flaky";
import HistoryIcon from "@mui/icons-material/History";
import { useNavigate, useLocation } from "react-router-dom";
// import { createBrowserHistory } from "history";
import styled from "@mui/material/styles/styled";

// const customHistory = createBrowserHistory();

const path = {
  "/": 0,
  "/approval": 1,
  "/history": 2
};

export default function IconLabelTabs(props) {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setValue(path[location.pathname]);
  }, [location.pathname]);

  const handleChange = (path) => () => {
    navigate(`/${path}`);
  };

  return (
    <Footer>
      <FooterContainer>
        <CustomTabs
          value={value}
          aria-label="icon label tabs example"
        >
          <Tab
            name={" "}
            onClick={handleChange("")}
            icon={<AccountCircleIcon name={" "} />}
            label="Home"
          />
          <Tab
            name="approval"
            onClick={handleChange("approval")}
            icon={<FlakyIcon name="approval" />}
            label="Approval"
          />
          <Tab
            name="history"
            onClick={handleChange("history")}
            icon={<HistoryIcon name="history" />}
            label="History"
          />
        </CustomTabs>
      </FooterContainer>
    </Footer>
  );
}

const CustomTabs = styled(Tabs)({
  width: "100%",
  justifyContent: "space-between"
});

const Footer = styled("div")({
  position: "fixed",
  bottom: 0,
  width: "100%"
});

const FooterContainer = styled("div")({
  display: "flex",
  width: "calc(100% - 16px)",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "0px 8px",
  background: "#ffffff",
  top: "auto"
  // position: "absolute"
});
