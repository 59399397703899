import callApi from "../../lib/fetch";
import cookie from "js-cookie";
import head from "lodash/head";
import isEmpty from "lodash/isEmpty";

const initResponse = {
  allHistoryDocument: 0,
  approvedDocument: 0,
  isOverDue: 0,
  isUrgent: 0,
  rejectedDocument: 0,
  waitApproveDocument: 0
};
const getDashbord = async () => {
  const body = {
    waitApproveBy: cookie.get("userId")
  };
  return new Promise((resolve, reject) => {
    callApi
      .post("/approval/mobiledashboard", body)
      .then(response => {
        let data = head(response?.data?.responseData);
        if (isEmpty(data)) {
          data = initResponse;
        }
        resolve(data);
      })
      .catch(error => {
        reject(error);
      })
      .finally(() => {});
  });
};

export { getDashbord };
