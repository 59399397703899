import Chip from "@mui/material/Chip";
import { styled } from "@mui/material/styles";
import { OVERDUE, URGENT, APPROVED, REJECTED, ACTIVE } from "../../utils";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import BoltIcon from "@mui/icons-material/Bolt";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import statusColors from "../../themes/statas";

const StatusButtonComponent = ({ statusCode, text, onClick }) => {
  return (
    <StatusButton
      label={
        <LabelComponent
          {...statusColors[statusCode]}
          statusCode={statusCode}
          text={text}
        />
      }
      variant="outlined"
      size="small"
      statusCode={statusCode}
      onClick={onClick}
    />
  );
};

const StatusButton = styled(Chip)(({ statusCode }) => ({
  background: statusColors[statusCode].background,
  height: "24px",
  border: `1px solid ${statusColors[statusCode].borderColor}`,
  marginRight: "8px"
}));

const Text = styled(Typography)(({ color }) => ({
  alignSelf: "center",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "12px",
  lineHeight: "16px",
  letterSpacing: "0.48px",
  flexGrow: "0",
  fontFeatureSettings: "'ss03' on, 'ss04' on, 'ss01' on, 'case' on",
  color,
  flex: "none",
  order: "1",
  margin: "0px 2px"
}));

const getIconStatus = statusCode => {
  const style = { width: "16px", height: "16px" };
  style.color = statusColors[statusCode].textColor;
  switch (statusCode) {
    case OVERDUE:
      return <PriorityHighIcon style={style} />;
    case URGENT:
      return <BoltIcon style={style} />;
    case APPROVED:
      return <DoneIcon style={style} />;
    case REJECTED:
      return <CloseIcon style={style} />;
    case ACTIVE:
      return <DoneIcon style={style} />;
    default:
      return;
  }
};

const Flex = styled("div")({
  display: "flex"
});

const LabelComponent = ({ statusCode, text }) => (
  <Flex>
    {getIconStatus(statusCode)}
    <Text color={statusColors[statusCode].textColor}>{text}</Text>
  </Flex>
);

StatusButtonComponent.defaultProps = {
  statusCode: "default",
  text: "",
  onClick: () => {}
};

export default StatusButtonComponent;
