export const OVERDUE = 1;
export const URGENT = 2;
export const APPROVED = 3;
export const REJECTED = 4;
export const ACTIVE = 99;

export const DEFAULT = 1;
export const RESET = 2;
export const ENABLED = 3;
export const REJECT_BUTTON = 4;
export const APPROVE_BUTTON = 5;
export const DISABLE = 6;
export const CANCLE_APPROVED = 7;

export const statusDocuments = {
  OVERDUE,
  URGENT,
  APPROVED,
  REJECTED,
  ACTIVE,
}