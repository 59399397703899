import callApi from "../../lib/fetch";
import cookies from "js-cookie";

const getPurchasingGroups = (filter = {}) => {
  const userId = cookies.get("userId");
  return new Promise((resolve, reject) => {
    callApi
      .get(`/approval/master/purchasinggroup/${userId}`, filter)
      .then(response => {
        resolve(response.data.responseData.map(value => ({
          text: value.purGroupName,
          value:value.purgId,
        })));
      })
      .catch(error => {
        reject(error);
      })
      .finally(() => {});
  });
};

export { getPurchasingGroups };
