import * as React from "react";
import Card from "@mui/material/Card";

export default function CardBase({ children, onClick }) {
  return (
    <Card
      onClick={onClick}
      style={{ marginBottom: "16px", borderRadius: "16px" }}
      sx={{ minWidth: 275 }}
    >
      {children}
    </Card>
  );
}
