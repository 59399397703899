import styled from "@mui/material/styles/styled";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ButtonConfirm from "../../components/Button/Confirm";
import CloseIcon from "@mui/icons-material/Close";
import ReplayCircleFilled from "@mui/icons-material/ReplayCircleFilled";
import DoneIcon from "@mui/icons-material/Done";
import { REJECT_BUTTON, APPROVE_BUTTON, CANCLE_APPROVED } from "../../utils";
import ModalApprove from "../modals/ModalApprove";
import { useState, useMemo } from "react";
import ModalReject from "../modals/ModalReject";
import { ModalContext } from "../../contexts/model-context";
import cookie from "js-cookie";
import Skeleton from "@mui/material/Skeleton";

const useModal = () => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const value = useMemo(
    () => ({ open, handleOpen, handleClose, isLoading, setIsLoading }),
    [open, isLoading]
  );
  return value;
};

const FooterAppove = props => {
  const {
    amount,
    id,
    titlePayment,
    userCancelApproved,
    waitApproveBy,
    getDatail,
    isReject,
    isApprove
  } = props;
  const useModalApprove = useModal();
  const useModalReject = useModal();
  const userId = cookie.get("userId");
  let isShowComfirmButton = waitApproveBy.userId.toString() === userId;
  let isShowCancelApproved = userCancelApproved.userId.toString() === userId;

  if (waitApproveBy.userId.toString() === "0") {
    return (
      <Footer>
        <Line />
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <TextTitle>Amount (THB):</TextTitle>
          </Grid>
          <Grid item xs={6}>
            <TextValue>฿ {amount}</TextValue>
          </Grid>
          <Skeleton
            style={{
              padding: "16px",
              marginRight: "16px",
              marginLeft: "16px",
              marginBottom: "20px"
            }}
            width="100%"
          />
        </Grid>
      </Footer>
    );
  }
  if (isApprove === undefined) {
    if (!isReject && !isApprove) {
      isShowComfirmButton = true;
      isShowCancelApproved = false;
    }
  }

  return (
    <Footer>
      <Line />
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <TextTitle>Amount (THB):</TextTitle>
        </Grid>
        <Grid item xs={6}>
          <TextValue>฿ {amount}</TextValue>
        </Grid>
        {isShowCancelApproved ? (
          <ButtonCancelApproved
            id={id}
            amount={amount}
            getDatail={getDatail}
            titlePayment={titlePayment}
            isReject={isReject}
            isApprove={isApprove}
          />
        ) : (
          isShowComfirmButton && (
            <Grid container spacing={0}>
              <GridP16 item xs={6}>
                <ModalContext.Provider value={useModalReject}>
                  <ModalReject
                    id={[id]}
                    amount={amount}
                    type={REJECT_BUTTON}
                    getDatail={getDatail}
                    title="Confirm to Reject"
                    titlePayment={titlePayment}
                    isReject={isReject}
                    isApprove={isApprove}
                  />
                  <ButtonConfirm
                    type={REJECT_BUTTON}
                    onClick={useModalReject.handleOpen}
                    variant="contained"
                  >
                    <CloseIcon style={{ marginRight: 8 }} />
                    Reject
                  </ButtonConfirm>
                </ModalContext.Provider>
              </GridP16>
              <GridP16 item xs={6}>
                <ModalContext.Provider value={useModalApprove}>
                  <ModalApprove
                    id={[id]}
                    amount={amount}
                    titlePayment={titlePayment}
                    getDatail={getDatail}
                  />
                  <ButtonConfirm
                    type={APPROVE_BUTTON}
                    onClick={useModalApprove.handleOpen}
                    variant="contained"
                  >
                    <DoneIcon style={{ marginRight: 8 }} />
                    Approve
                  </ButtonConfirm>
                </ModalContext.Provider>
              </GridP16>
            </Grid>
          )
        )}
      </Grid>
    </Footer>
  );
};

const ButtonCancelApproved = ({
  id,
  amount,
  titlePayment,
  getDatail,
  isReject,
  isApprove
}) => {
  const useCancelApproved = useModal();

  return (
    <Grid container spacing={0}>
      <GridP16 item xs={12}>
        <ModalContext.Provider value={useCancelApproved}>
          <ModalReject
            id={[id]}
            amount={amount}
            isReject={isReject}
            isApprove={isApprove}
            getDatail={getDatail}
            title={
              isApprove
                ? "Confirm to Cancel Approved"
                : "Confirm to Cancel Reject"
            }
            titlePayment={titlePayment}
            type={CANCLE_APPROVED}
          />
          <ButtonConfirm
            type={CANCLE_APPROVED}
            onClick={useCancelApproved.handleOpen}
            variant="contained"
          >
            <ReplayCircleFilled style={{ marginRight: 8 }} />
            Cancel {isApprove ? "Approved" : "Reject"}
          </ButtonConfirm>
        </ModalContext.Provider>
      </GridP16>
    </Grid>
  );
};

FooterAppove.defaultProps = {
  amount: 0,
  onClickApprove: () => {},
  userCancelApproved: { userId: 0 },
  waitApproveBy: { userId: 0 }
};
const Line = styled("hr")({
  marginTop: 0
});

const GridP16 = styled(Grid)({
  padding: "16px"
});

const Footer = styled("div")({
  position: "fixed",
  bottom: 0,
  width: "100%",
  color: "white",
  textAlign: "center",
  background: "#FFFFFF"
});

const TextTitle = styled(Typography)({
  fontFamily: "FC Iconic",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "15px",
  lineHeight: "20px",
  letterSpacing: "0.48px",
  color: "#545454"
});

const TextValue = styled(Typography)({
  fontFamily: "FC Iconic",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "15px",
  lineHeight: "20px",
  letterSpacing: "0.48px",
  color: "#174291"
});

export default FooterAppove;
