import { useContext } from "react";
import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { ModalContext } from "../../contexts/model-context";
import ButtonConfirm from "../../components/Button/Confirm";
import postMessage from "../../helpers/PostMessage";

const drawerBleeding = 56;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800]
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)"
}));

const fileTypesImage = {
  jpg: true,
  png: true
};

const fileTypesPdf = {
  pdf: true
};

function SwipeableEdgeDrawer(props) {
  const { window, file } = props;
  const { open, handleClose, handleOpen } = useContext(ModalContext);
  file.fileExtension = file.fileExtension?.toLowerCase();
  let isShowImage = fileTypesImage[file.fileExtension];
  let isShowPdf = fileTypesPdf[file.fileExtension];

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const dowload = () => {
    
    postMessage({
      type: "dowload",
      url: file.filePath,
      fileName: file.name,
      fileExtension: file.fileExtension
    });
  };


  return (
    <Root>
      <CssBaseline />
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(90% - ${drawerBleeding}px)`,
            overflow: "visible"
          }
        }}
      />
      <SwipeableDrawer
        container={container}
        anchor="bottom"
        styles={{
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8
        }}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true
        }}
      >
        <StyledBox
          sx={{
            position: "absolute",
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            paddingTop: 8,
            right: 0,
            left: 0,
            px: 2,
            pb: 2,
            height: "100%"
          }}
        >
          <Puller />
          {isShowPdf && (
            <iframe
              src={`https://docs.google.com/viewer?url=${file.filePath}&embedded=true`}
              height="100%"
              width="100%"
              title="Iframe"
            ></iframe>
          )}

          {isShowImage && (
            <img
              alt={file.filePath}
              src={file.filePath}
              height="100%"
              width="100%"
            />
          )}
          <ButtonConfirm type={1} onClick={dowload} variant="contained">
            Dowload
          </ButtonConfirm>
        </StyledBox>
      </SwipeableDrawer>
    </Root>
  );
}

SwipeableEdgeDrawer.defaultProps = {
  type: "jpg",
  file: {}
};

SwipeableEdgeDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
};

export default SwipeableEdgeDrawer;
