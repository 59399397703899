import CardBase from "./CardBase";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import StatusButton from "../StatusButton";
import { OVERDUE, URGENT, statusDocuments } from "../../utils";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import { getFormatDayJs } from "../../utils/format/date";
import { getMoneyFormat } from "../../utils/format/money";
import dayjs from "dayjs";

const PaymentCard = ({
  company,
  purGroup,
  fileSapnum,
  prHeaderNote,
  dueDate,
  amount,
  isUrgent,
  onClick,
  payId,
  approverStatus
}) => {
  let status = [];
  if (approverStatus) {
    status.push(
      <MarginRight key={`${payId}-Overdue`}>
        <StatusButton
          statusCode={statusDocuments[approverStatus]}
          text={approverStatus}
        />
      </MarginRight>
    );
  }
  if (!approverStatus && isOverdue(dueDate)) {
    status.push(
      <MarginRight key={`${payId}-Overdue`}>
        <StatusButton statusCode={OVERDUE} text="Overdue" />
      </MarginRight>
    );
  }
  if (!approverStatus && isUrgent) {
    status.push(
      <MarginRight key={`${payId}-Urgent`}>
        <StatusButton statusCode={URGENT} text="Urgent" />
      </MarginRight>
    );
  }

  return (
    <CardBase onClick={onClick}>
      <CardContentRelative>
        <HeaderCard>
          <Title>
            {company} • {purGroup}
          </Title>
          <Flex>
            <AttachFile />
            <Typography>{fileSapnum}</Typography>
          </Flex>
        </HeaderCard>
        <div>
          <Description>{prHeaderNote}</Description>
        </div>
        <ButtonContainer>{status}</ButtonContainer>
        <HeaderCard>
          <Flex>
            <RunningWithErrors />
            <Title>{getFormatDayJs(dueDate, "DD.MM.YYYY")}</Title>
          </Flex>
          <TextButtomEnd>{getMoneyFormat(amount)}</TextButtomEnd>
        </HeaderCard>
      </CardContentRelative>
    </CardBase>
  );
};

PaymentCard.defaultProps = {
  company: "company",
  purGroup: "purGroup",
  fileSapnum: "1",
  prHeaderNote: "-",
  dueDate: new Date(),
  amount: 10000,
  isUrgent: true
};

const MarginRight = styled("div")({
  marginRight: "8px"
});

const isOverdue = date => {
  const dueDate = dayjs(date);
  const toDay = dayjs();
  const diff = dueDate.diff(toDay);
  return moreZero(diff);
};

const moreZero = diff => diff < 0;

const TextButtomEnd = styled(Typography)({
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "19px",
  lineHeight: "24px",
  textAlign: "right",
  letterSpacing: "0.48px",
  color: "#174291"
});

const ButtonContainer = styled("div")({
  marginTop: "16px",
  marginBottom: "16px",
  display: "flex"
});

const RunningWithErrors = styled(RunningWithErrorsIcon)({
  marginRight: "8px",
  height: "20px",
  width: "20px"
});

const CardContentRelative = styled(CardContent)({
  position: "relative",
  "&:last-child": {
    paddingBottom: "16px"
  }
});

const AttachFile = styled(AttachFileIcon)({
  width: "14px",
  height: "18.33px"
});

const Title = styled(Typography)({
  fontFamily: "FC Iconic",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "14px",
  lineHeight: "20px",
  letterSpacing: "0.2px",
  color: "#545454",
  fontFeatureSettings: "'ss03' on, 'ss04' on, 'ss01' on, 'case' on"
});

const HeaderCard = styled("div")({
  display: "flex",
  justifyContent: "space-between"
});

const Flex = styled("div")({
  display: "flex"
});

const Description = styled(Typography)({
  fontFamily: "FC Iconic",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "19px",
  lineHeight: "24px",
  letterSpacing: "0.48px",
  fontFeatureSettings: "'ss03' on, 'ss04' on, 'ss01' on, 'case' on",
  color: "#141414"
});

export default PaymentCard;
