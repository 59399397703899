import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@mui/material/styles/styled";

export default function ModalBase({ body, title, handleClose, open, footer }) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={style}>
        <HeaderModel>
          <HeaderTitle>{title}</HeaderTitle>
          <Close onClick={handleClose} />
        </HeaderModel>
        <hr />
        <Body>{body}</Body>
        <hr />
        <Footer>{footer}</Footer>
      </Box>
    </Modal>
  );
}

const Close = styled(CloseIcon)({
  marginRight: "16px"
});

const HeaderModel = styled("div")({
  display: "flex",
  justifyContent: "space-between"
});

const Footer = styled("div")({
  display: "flex"
});

const HeaderTitle = styled(Typography)({
  fontFamily: "FC Iconic",
  marginLeft: "16px",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "19px",
  lineHeight: "24px",
  letterSpacing: "0.48px",
  fontFeatureSettings: "'ss03' on, 'ss04' on, 'ss01' on, 'case' on",
  color: "#141414"
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "16px",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.12)",
  pt: 2,
  width: "380px",
  pb: 3
};

const Body = styled("div")({
  padding: "18px",
  maxHeight: "300px",
  overflow: "auto"
});

ModalBase.defualtProps = {
  title: "Purchasing Group",
  handleClose: () => {},
  open: false,
  body: <></>,
  footer: <></>
};
