import React, { useState, useContext } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AuthContext } from "../contexts/auth";
import Loading from "../components/Loading";
// import MicrosoftLogin from "react-microsoft-login";
// import styled from "@mui/material/styles/styled";
import { useNavigate } from "react-router-dom";

const onInput = (setState, name) => event => {
  setState(state => ({ ...state, [name]: event.target.value }));
};

const theme = createTheme();

const initFormLogin = { userName: "", password: "" };

function SignInSide() {
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const [{ userName, password }, setForm] = useState(initFormLogin);
  const [loadingPage, setLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await login({ userName, password });
      navigate("/");
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        {loadingPage && <Loading />}
        <CssBaseline />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <img alt="logo" src="/assets/BEC.svg" />
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                value={userName}
                onChange={onInput(setForm, "userName")}
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                onChange={onInput(setForm, "password")}
                value={password}
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button
                type="submit"
                onClick={handleSubmit}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 0 }}
              >
                Sign In
              </Button>
            </Box>
            <hr />
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

// const MicrosoftLoginCustom = styled(MicrosoftLogin)({});

function LoginPage() {
  return (
    <div>
      <SignInSide />
    </div>
  );
}

export default LoginPage;
