import styled from "@mui/material/styles/styled";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ButtonConfirm from "../components/Button/Confirm";
import Grid from "@mui/material/Grid";
import { useContext } from "react";
import { AuthContext } from "../contexts/auth";

function ProfilePage() {
  const { logout } = useContext(AuthContext);

  return (
    <div>
      <Navbar />
      <GridPadding container spacing={5}>
        <Grid item xs={12}>
          <ButtonConfirm onClick={logout} type={1} variant="contained">
            Logout
          </ButtonConfirm>
        </Grid>
      </GridPadding>
      <Footer />
    </div>
  );
}

const GridPadding = styled(Grid)({
  padding: "16px",
  top: "500px",
  position: "absolute"
});

export default ProfilePage;
