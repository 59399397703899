import styled from "@mui/material/styles/styled";
import buttonThemes from "../../themes/buttons";
import { DEFAULT } from "../../utils";

const ButtonBase = props => {
  return <ButtonC {...props} />;
};
const ButtonC = styled("button")(({ type }) => {
  return {
    fontSize: "19px",
    padding: "10px 20px",
    borderRadius: "8px",
    fontFamily: "FC Iconic",
    backgroundColor: buttonThemes[type].backgroundColor, //"#545454", //"#0063cc",
    border: `1px solid ${buttonThemes[type].borderColor}`,
    color: buttonThemes[type].textColor,
    textTransform: "capitalize",
    "&:disabled": {
      backgroundColor: "#AFAFAF",
      border: `1px solid #AFAFAF`
    },
  };
});

ButtonBase.defaultProps = {
  type: DEFAULT
};

export default ButtonBase;
