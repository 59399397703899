import React from "react";
import CardBase from "./CardBase";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import StatusButton from "../../components/StatusButton";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import statusColors from "../../themes/statas";

const DashBordCardBase = ({
  title,
  subTitle,
  amount,
  iconCardBase,
  buttons
}) => {
  return (
    <CardBase>
      <CardContentCustom>
        <TitleCard
          sx={{ fontSize: "19px" }}
          color="text.secondary"
          gutterBottom
        >
          {title}
        </TitleCard>
        <SubTitle component="div">{subTitle}</SubTitle>
        <Flex>
          <AmountText color="text.secondary">{amount}</AmountText>
          <ItemText>Items</ItemText>
        </Flex>
        <IconContainer>
          {iconCardBase}
        </IconContainer>
        <Grid container spacing={2}>
          {buttons.map(button => {
            return (
              <GridFlex key={button.statusCode} item xs={6}>
                <StatusButton
                  statusCode={button.statusCode}
                  text={button.text}
                />
                <AmountStatusText statusCode={button.statusCode}>
                  {button.amount}
                </AmountStatusText>
              </GridFlex>
            );
          })}
        </Grid>
      </CardContentCustom>
    </CardBase>
  );
};

const GridFlex = styled(Grid)({
  display: "flex"
});

const CardContentCustom = styled(CardContent)({
  position: "relative"
});

const TitleCard = styled(Typography)({
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "18px",
  lineHeight: "24px",
  letterSpacing: "0.24px",
  fontFeatureSettings: "'ss03' on, 'ss04' on, 'ss01' on, 'case' on",
  color: "#141414"
});

const SubTitle = styled(Typography)({
  fontSize: "13px",
  lineHeight: "16px",
  letterSpacing: "0.32px",
  color: "#757575"
});

const AmountText = styled(Typography)({
  fontFamily: "FC Iconic",
  fontStyle: "normal",
  fontWeight: "300",
  fontSize: "64px",
  lineHeight: "72px",
  color: "#174291"
});

const ItemText = styled(Typography)({
  alignSelf: "end",
  fontFamily: "FC Iconic",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "15px",
  lineHeight: "20px",
  paddingBottom: "10px",
  color: "#757575",
  marginLeft: "8px"
});

const IconContainer = styled("div")({
  position: "absolute",
  width: "88px",
  height: "128px",
  top: "16px",
  right: "-16px",
  opacity: "0.08"
});

const AmountStatusText = styled(Typography)(({ statusCode }) => ({
  alignSelf: "center",
  justifyContent: "center",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "15px",
  lineHeight: "20px",
  letterSpacing: "0.44px",
  flexGrow: "0",
  fontFeatureSettings: "'ss03' on, 'ss04' on, 'ss01' on, 'case' on",
  flex: "none",
  order: "1",
  margin: "0px 8px",
  color: statusColors[statusCode].textColor
}));

const Flex = styled("div")({
  display: "flex"
});

export default DashBordCardBase;
