import { useContext, useState } from "react";
import ModalBase from "./ModalBase";
import Grid from "@mui/material/Grid";
import styled from "@mui/material/styles/styled";
import { ModalContext } from "../../contexts/model-context";
import ButtonConfirm from "../../components/Button/Confirm";
import CloseIcon from "@mui/icons-material/Close";
import ReplayCircleIcon from "@mui/icons-material/ReplayCircleFilled";
import {
  APPROVE_BUTTON,
  CANCLE_APPROVED,
  REJECT_BUTTON,
  DISABLE
} from "../../utils";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import {
  sendApproversPaymentHistory,
  rejectPaymentHistory
} from "../../api/payments/history";
import Swal from "sweetalert2";
import cookie from "js-cookie";
import getMeesageError from "../../utils/format/error";
import onChange from "../../utils/useStates/input";
import CircularProgress from "@mui/material/CircularProgress";

const setBodysendApproversPaymentHistory = async id => {
  return {
    payId: id,
    userId: await cookie.get("userId")
  };
};

const ModalReject = ({
  title,
  id,
  amount,
  titlePayment,
  type,
  getDatail,
  isReject,
  isApprove
}) => {
  const { open, handleClose, setIsLoading, isLoading } = useContext(
    ModalContext
  );
  const [value, setValue] = useState();
  const onClick = async () => {
    setIsLoading(true);
    try {
      const body = await setBodysendApproversPaymentHistory(id);
      body.reason = value;
      body.isCancel = false;
      if (isReject || isApprove) {
        body.isCancel = true;
      }
      if (isReject || type === REJECT_BUTTON) {
        await rejectPaymentHistory(body);
      } else {
        await sendApproversPaymentHistory(body);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Apporved Faild",
        text: getMeesageError(error)
      });
    } finally {
      setIsLoading(false);
      handleClose();
      getDatail(id);
    }
  };
  return (
    <ModalBase
      body={
        <Grid container>
          <Grid container>
            Are you sure to Approve {titlePayment} for {amount} bath?
          </Grid>
          <Grid container>
            <TextInput color="#141414">Reason</TextInput>
            <TextInput color="#D82E18">*</TextInput>
          </Grid>
          <CustomTextareaAutosize onChange={onChange(setValue)} minRows={4} />
        </Grid>
      }
      footer={
        <Grid container spacing={0}>
          <GridPadding item xs={12}>
            <Button
              isLoading={isLoading}
              isReject={isReject}
              isApprove={isApprove}
              disabled={isLoading || !value}
              onClick={onClick}
              type={type}
            />
          </GridPadding>
        </Grid>
      }
      title={title}
      open={open}
      handleClose={handleClose}
    />
  );
};

const Button = ({
  type,
  onClick,
  disabled,
  isLoading,
  isReject,
  isApprove
}) => {
  let Icon;
  let text;
  switch (type) {
    case CANCLE_APPROVED:
      Icon = ReplayCircleIcon;
      text = "Cancel Approved";
      break;
    case REJECT_BUTTON:
      Icon = CloseIcon;
      text = "Reject";
      break;
    default:
      Icon = CloseIcon;
      text = "Reject";
      break;
  }

  if (isReject || isApprove) {
    if (isReject) {
      text = "Cancel Reject";
    }
  }
  return (
    <ButtonConfirm
      type={disabled ? DISABLE : type}
      onClick={onClick}
      variant="contained"
    >
      {isLoading ? <CircularProgress size={16} /> : <Icon />}
      {text}
    </ButtonConfirm>
  );
};

const CustomTextareaAutosize = styled(TextareaAutosize)({
  fontFamily: "FC Iconic",
  fontSize: "18px",
  background: "#FFFFFF",
  border: "1.5px solid #174291",
  boxSizing: "border-box",
  borderRadius: "8px",
  flex: "none",
  order: 1,
  alignSelf: "stretch",
  flexGrow: 0,
  width: "100%"
});

const TextInput = styled("h6")(({ color }) => ({
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "15px",
  lineHeight: "20px",
  letterSpacing: "0.48px",
  paddingBottom: 8,
  marginBottom: 8,
  color
}));

const GridPadding = styled(Grid)({
  padding: "16px"
});

ModalReject.defaultProps = {
  title: "Confirm to Approve",
  type: APPROVE_BUTTON,
  titlePayment: "ขออนุมัติค่าใช้จ่ายในการบำรุงรักษาซอฟต์แวร์สำหรับแผนกไอที",
  amount: "55,426.00"
};

export default ModalReject;
