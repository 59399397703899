import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import SearchIcon from "@mui/icons-material/Search";
import styled from "@mui/material/styles/styled";
import StatusButton from "../../components/StatusButton";
import { ACTIVE } from "../../utils";

const NarbarFilter = ({ title, count, menu, buttonActives }) => {
  return (
    <NarbarContainder position="fixed">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { md: "none" } }}>
            <TitleIconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
            >
              {`${title} (${count})`}
            </TitleIconButton>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Search />
          </Box>
        </Toolbar>
      </Container>
      <FilterContainer className="hide-scrollbar" maxWidth="xl">
        <ButtonContainer>
          {menu.map(item => (
            <MarginRight key={item.key}>
              <StatusButton
                onClick={item.onClick}
                statusCode={buttonActives[item.key] ? ACTIVE : undefined}
                text={getTextButton(buttonActives, item)}
              />
            </MarginRight>
          ))}
        </ButtonContainer>
      </FilterContainer>
    </NarbarContainder>
  );
};

const getTextButton = (buttonActives, item) =>
  buttonActives[item.key]
    ? buttonActives[item.key].text || item.name
    : item.name;

const MarginRight = styled("div")({
  marginRight: "8px"
});
const NarbarContainder = styled(AppBar)({
  background: "#FFFFFF"
});

const TitleIconButton = styled(IconButton)({
  padding: 0,
  color: "#141414",
  fontFamily: "FC Iconic",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "24px",
  lineHeight: "36px"
});

const Search = styled(SearchIcon)({
  color: "#141414"
});

const FilterContainer = styled(Container)({
  width: "100%",
  margin: "14px 0px",
  overflowX: "scroll"
});

const ButtonContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start"
});

NarbarFilter.defaultProps = {
  title: "Approval",
  count: 4,
  menu: ["Overdue", "Urgent", "Purchasing Group", "Company"],
  buttonActives: {}
};

export default NarbarFilter;
