import { useContext, useState } from "react";
import ModalBase from "./ModalBase";
import Grid from "@mui/material/Grid";
import styled from "@mui/material/styles/styled";
import { ModalContext } from "../../contexts/model-context";
import ButtonConfirm from "../../components/Button/Confirm";
import { APPROVE_BUTTON } from "../../utils";
import DoneIcon from "@mui/icons-material/Done";
import { sendApproversPaymentHistory } from "../../api/payments/history";
import cookie from "js-cookie";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";

const setBodysendApproversPaymentHistory = async id => {
  return {
    payId: id,
    userId: await cookie.get("userId"),
    reason: "",
    isCancel: false
  };
};

const getMeesageError = error => {
  return error.response.data.error;
};

const ModalApprove = ({ title, titlePayment, amount, id, getDatail }) => {
  const { open, handleClose } = useContext(ModalContext);
  const [isLoading, setIsLoading] = useState(false);
  const onClickApprove = async () => {
    setIsLoading(true);
    try {
      const body = await setBodysendApproversPaymentHistory(id);
      await sendApproversPaymentHistory(body);
    } catch (error) {
      getMeesageError(error);
      Swal.fire({
        icon: "error",
        title: "Apporved Faild",
        text: getMeesageError(error)
      });
    } finally {
      setIsLoading(false);
      getDatail(id);
      handleClose();
    }
  };

  return (
    <ModalBase
      body={
        <>
          Are you sure to Approve {titlePayment} for {amount} bath?
        </>
      }
      footer={
        <Grid container spacing={0}>
          <GridPadding item xs={12}>
            <ButtonConfirm
              type={APPROVE_BUTTON}
              onClick={onClickApprove}
              disabled={isLoading}
              variant="contained"
            >
              {isLoading ? <CircularProgress size={16} /> : <DoneIcon />}
              Approve
            </ButtonConfirm>
          </GridPadding>
        </Grid>
      }
      title={title}
      open={open}
      handleClose={handleClose}
    />
  );
};

const GridPadding = styled(Grid)({
  padding: "16px"
});

ModalApprove.defaultProps = {
  title: "Confirm to Approve",
  titlePayment: "ขออนุมัติค่าใช้จ่ายในการบำรุงรักษาซอฟต์แวร์สำหรับแผนกไอที",
  amount: "55,426.00"
};

export default ModalApprove;
