import { useContext, useState } from "react";
import ModalBase from "./ModalBase";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Button from "../Button";
import Grid from "@mui/material/Grid";
import styled from "@mui/material/styles/styled";
import { ModalContext } from "../../contexts/model-context";
import {
  // DEFAULT,
  RESET,
  ENABLED,
  // REJECT_BUTTON,
  // APPROVE_BUTTON
} from "../../utils/index";

const ModelSelect = ({ title, options, setSelect }) => {
  const { open, handleClose } = useContext(ModalContext);
  const [value, setValue] = useState();
  const handleChange = event => {
    setValue(event.target.value);
  };
  const handleApply = () => {
    const optionValue = parseInt(value, 10);
    const option = options.find(option => option.value === optionValue);
    setSelect(option);
    handleClose();
  };
  const handleReset = () => {
    setSelect(undefined);
    handleClose();
  };
  return (
    <ModalBase
      body={
        <FormControl>
          <RadioGroup
            onChange={handleChange}
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
          >
            {options.map(option => (
              <FormControlLabel
                key={option.text}
                value={option.value}
                control={<Radio />}
                label={option.text}
              />
            ))}
          </RadioGroup>
        </FormControl>
      }
      footer={
        <Grid container spacing={0}>
          <GridPadding item xs={6}>
            <ButtonCustom
              onClick={handleReset}
              type={RESET}
              variant="contained"
            >
              Reset
            </ButtonCustom>
          </GridPadding>
          <GridPadding item xs={6}>
            <ButtonCustom
              onClick={handleApply}
              disabled={!value}
              type={ENABLED}
              variant="contained"
            >
              Apply
            </ButtonCustom>
          </GridPadding>
        </Grid>
      }
      title={title}
      open={open}
      handleClose={handleClose}
    />
  );
};

const GridPadding = styled(Grid)({
  padding: "16px"
});

const ButtonCustom = styled(Button)({
  width: "100%"
});

ModelSelect.defaultProps = {
  title: "",
  options: []
};

export default ModelSelect;
