import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import styled from "@mui/material/styles/styled";
import { Link } from "react-router-dom";

const Navbar = ({ firstName, lastName }) => {
  const getIconName = () => {
    return `${getFirstCharOfString(firstName)}${getFirstCharOfString(
      lastName
    )}`;
  };

  return (
    <NavbarContainer position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { md: "none" } }}>
            <Flex>
              <CastomIconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
              >
                <img alt="logo" src="/assets/BEC.svg" />
              </CastomIconButton>
              <div>
                <NameApp color="#545454">Payment</NameApp>
                <NameApp color="#174291">Approval</NameApp>
              </div>
            </Flex>
          </Box>
          <Link to="/profile">
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton sx={{ p: 0 }}>
                  <CustomAvatar alt="Remy Sharp">{getIconName()}</CustomAvatar>
                </IconButton>
              </Tooltip>
            </Box>
          </Link>
        </Toolbar>
      </Container>
    </NavbarContainer>
  );
};

const getFirstCharOfString = string => {
  if (string.length === 0) {
    return "";
  }
  return string[0];
};

Navbar.defaultProps = {
  firstName: "N",
  lastName: "P"
};

const CustomAvatar = styled(Avatar)({
  background: "#545454"
});

const NavbarContainer = styled(AppBar)({
  background: "#FFFFFF"
});

const NameApp = styled("p")(({ color }) => ({
  fontWeight: "500",
  fontSize: "13px",
  lineHeight: "20px",
  letterSpacing: "0.2px",
  margin: 0,
  padding: 0,
  color
}));

const CastomIconButton = styled(IconButton)({
  padding: 0,
  marginRight: "8px"
});

const Flex = styled("div")({
  display: "flex"
});

export default Navbar;
