import { useState, useMemo } from "react";

const useModal = () => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const value = useMemo(
    () => ({ open, handleOpen, handleClose, isLoading, setIsLoading }),
    [open, isLoading]
  );
  return value;
};

export default useModal;
