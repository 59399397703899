import { useEffect, useState, useMemo } from "react";
import NarbarFilter from "../components/Narbars/NarbarFilter";
import PaymentCard from "../components/Card/PaymentCard";
import styled from "@mui/material/styles/styled";
import { getPaymentHistorys } from "../api/payments/history";
import { ModalContext } from "../contexts/model-context";
import ModelSelect from "../components/modals/ModalSelect";
import { getCompanies } from "../api/masters/companies";
import { getPurchasingGroups } from "../api/masters/purchasingGroups";
import Loadings from "../components/Loadings/LdsRing";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import cookie from "js-cookie";
import isEmpty from "lodash/isEmpty";

const useModal = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const value = useMemo(() => ({ open, handleOpen, handleClose }), [open]);
  return value;
};

const useFilter = () => {
  const [filter, setFilter] = useState({});

  useEffect(() => {
    getFilter().then(data => {
      setFilter(data);
    });
    return () => {};
  }, []);

  const setStatus = name => () => {
    setFilter(state => ({ ...state, [name]: !state[name] }));
  };

  const setSelect = name => value => {
    setFilter(state => ({ ...state, [name]: value }));
  };
  const value = useMemo(() => ({ filter, setStatus, setSelect }), [filter]);
  return value;
};

function ApprovePage() {
  const [payments, setPayments] = useState([]);
  const useModalPurchasingGroup = useModal();
  const [options, setOptions] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const useModalCompany = useModal();
  const navigate = useNavigate();
  const filter = useFilter();

  const onClickCard = id => () => {
    navigate(`/approval/${id}`);
  };

  const setPropsNarbarFilter = () => {
    return [
      { key: "overdue", name: "Overdue", onClick: filter.setStatus("overdue") },
      { key: "urgent", name: "Urgent", onClick: filter.setStatus("urgent") },
      {
        key: "purchasingGroup",
        name: (
          <p>
            Purchasing Group
            <img
              width="10px"
              height="5px"
              alt="arrow_drop_down"
              src="/assets/arrow_drop_down.svg"
            />
          </p>
        ),
        onClick: useModalPurchasingGroup.handleOpen
      },
      {
        key: "company",
        name: (
          <p>
            Company
            <img
              width="10px"
              height="5px"
              alt="arrow_drop_down"
              src="/assets/arrow_drop_down.svg"
            />
          </p>
        ),
        onClick: useModalCompany.handleOpen
      }
    ];
  };
  useEffect(() => {
    getCompanies()
      .then(response => {
        setOptions(state => ({ ...state, companies: response }));
      })
      .catch(error => {});
    getPurchasingGroups()
      .then(response => {
        setOptions(state => ({ ...state, purchasingGroups: response }));
      })
      .catch(error => {});
  }, []);

  useEffect(() => {
    if (!isEmpty(filter.filter)) {
      getPaymentHistorys(filter.filter)
        .then(response => {
          setPayments(response);
          setIsLoading(false);
        })
        .catch(error => {});
    }
    return () => {};
  }, [filter.filter]);

  return (
    <Scean>
      <ModalContext.Provider value={useModalPurchasingGroup}>
        <ModelSelect
          setSelect={filter.setSelect("purchasingGroup")}
          options={options.purchasingGroups}
          title="Purchasing Group"
        />
      </ModalContext.Provider>
      <ModalContext.Provider value={useModalCompany}>
        <ModelSelect
          setSelect={filter.setSelect("company")}
          options={options.companies}
          title="Company"
        />
      </ModalContext.Provider>
      <NarbarFilter
        title="Approval"
        count={payments.length}
        buttonActives={filter.filter}
        menu={setPropsNarbarFilter()}
      />
      <Body>
        {isLoading ? (
          <LoadingCenter />
        ) : (
          payments.map(payment => {
            return (
              <PaymentCard
                key={payment.id}
                onClick={onClickCard(payment.payId)}
                {...payment}
              />
            );
          })
        )}
      </Body>
      <Footer />
    </Scean>
  );
}

const getFilter = async () => ({
  activityState: "pending",
  compId: null,
  purgId: null,
  postingDate: null,
  waitApproveBy: cookie.get("userId"),
  page: 1,
  perPage: 4
});

const LoadingCenter = styled(Loadings)({
  width: "100%",
  justifyContent: "center"
});

const Scean = styled("div")({
  position: "fixed",
  width: "100%",
  top: "108px",
  height: "calc(100% - 174px)",
  overflow: "auto"
});

const Body = styled("div")({
  height: "calc(100% - 16px)",
  padding: "16px"
});
export default ApprovePage;
