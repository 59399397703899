import callApi from "../../lib/fetch";
import cookies from "js-cookie";

const getCompanies = (filter = {}) => {
  const userId = cookies.get("userId");
  return new Promise((resolve, reject) => {
    callApi
      .get(`/approval/master/company/${userId}`, filter)
      .then(response => {
        resolve(
          response.data.responseData.map(value => ({
            text: value.companyName,
            value: value.compId
          }))
        );
      })
      .catch(error => {
        reject(error);
      })
      .finally(() => {});
  });
};

export { getCompanies };
