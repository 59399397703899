import { OVERDUE, URGENT, APPROVED, REJECTED, ACTIVE } from "../utils";

const statusTheme = {
  [OVERDUE]: {
    background: "#F4F1FA",
    borderColor: "#949CE3",
    textColor: "#0E1FC1"
  },
  [URGENT]: {
    background: "#FFF3EF",
    borderColor: "#FABDA5",
    textColor: "#C14F29"
  },
  [APPROVED]: {
    background: "#E6F2ED",
    borderColor: "#66D19E",
    textColor: "#66D19E"
  },
  [REJECTED]: {
    background: "#FFEFED",
    borderColor: "#D82E18",
    textColor: "#D82E18"
  },
  default: {
    background: "#F6F6F6",
    borderColor: "#E2E2E2",
    textColor: "#545454"
  },
  [ACTIVE]: {
    background: "#F4F1FA",
    borderColor: "#174291",
    textColor: "#174291"
  }
};

export default statusTheme;
