import { useState, useMemo, useEffect } from "react";
import { login as callLoginApi } from "../api/auth";
import cookie from "js-cookie";

const useAuth = () => {
  const [isAuth, setIsAuth] = useState(false);
  const [userProfile, setUserProfile] = useState({});

  useEffect(() => {
    if (cookie.get("token")) {
      setIsAuth(true);
    }
  }, []);

  const login = async form => {
    try {
      const userProfile = await callLoginApi(form);
      setUserProfile(userProfile);
      cookie.set("token", userProfile.tokenId);
      cookie.set("tokenDate", userProfile.tokenDate);
      cookie.set("userId", userProfile.userId);
      setIsAuth(true);
    } catch (error) {}
  };

  const logout = async () => {
    try {
      setUserProfile({});
      cookie.remove("token");
      cookie.remove("tokenDate");
      cookie.remove("userId");
      setIsAuth(false);
    } catch (error) {}
  };

  const value = useMemo(() => ({ isAuth, login, logout, userProfile }), [
    isAuth,
    userProfile
  ]);
  return value;
};

export default useAuth;
