import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Approve from "./pages/Approve";
import Detail from "./pages/Detail";
import History from "./pages/History";
import Login from "./pages/Login";
import { AuthContext } from "./contexts/auth";
import Profile from "./pages/Profile";
import useAuth from "./useContexts/useAuth";

function App() {
  const auth = useAuth();
  return (
    <AuthContext.Provider value={auth}>
      <Router>
        {auth.isAuth ? (
          <Routes>
            <Route element={<Profile />} path="/profile" />
            <Route element={<Detail />} path="/detail" />
            <Route element={<Detail />} path="/history/:id" />
            <Route element={<History />} path="/history" />
            <Route element={<Approve />} path="/approval" />
            <Route element={<Detail />} path="/approval/:id" />
            <Route element={<Home />} path="/" />
          </Routes>
        ) : (
          <Routes>
            <Route element={<Login />} path="*" />
          </Routes>
        )}
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
