import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as React from "react";
import PropTypes from "prop-types";
import styled from "@mui/material/styles/styled";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import SendIcon from "@mui/icons-material/Send";
import { getPaymentHistory } from "../api/payments/history";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FooterAppove from "../components/Footer/Appove";
import StatusButton from "../components/StatusButton";
import dayjs from "dayjs";
import { OVERDUE, URGENT, APPROVED, REJECTED } from "../utils";
import Skeleton from "@mui/material/Skeleton";
import isEmpty from "lodash/isEmpty";
import FlieTable from "../components/Tables/FlieTable";
import { useNavigate } from "react-router-dom";
import PlayCircleOutline from "@mui/icons-material/PlayCircleOutline";
import { getFormatDayJs } from "../utils/format/date";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

const moreZero = diff => diff < 0;

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const CustomTabPanel = styled(TabPanel)(() => ({
  ".MuiBox-root": {
    padding: "0px"
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0
  }
}));

const headers = [
  { name: "Due Date", key: "dueDateFormat" },
  { name: "Posting Date", key: "postingDateFormat" },
  { name: "FI No", key: "fiDocNo" },
  { name: "Purchasing Group", key: "purGroup" },
  { name: "Company", key: "company" },
  { name: "PR No", key: "prNo" },
  { name: "PO No", key: "poNo" },
  { name: "PR Header Note", key: "prHeaderNote" },
  { name: "Vendor", key: "accountText" },
  { name: "Tax Code", key: "taxCode" },
  { name: "Assignment", key: "assigment" },
  { name: "Payment Term", key: "paymentTerm" },
  { name: "Document Date", key: "documentDateFormat" },
  { name: "Reference", key: "reference" },
  { name: "Payment Ref", key: "paymentRef" }
];

const headerDetail = [
  { key: "exchangeRate", name: "(USD)" },
  { key: "paymentAssignment", name: "Assignment" },
  { key: "materialGroup", name: "Material Group" },
  { key: "orderNumberWbs", name: "WBS/Order No" },
  { key: "cost_center", name: "Cost Center" }
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontFamily: "FC Iconic",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "20px",
    letterSpacing: "0.48px",
    color: "#545454"
  }
}));

const MENU_BARS = ["Headers", "Details", "Attacheds", "Status"];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function DetailPage() {
  const [value, setValue] = useState(0);
  const [detail, setDetail] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();

  const getDatail = id => {
    setDetail({});
    getPaymentHistory(id).then(response => {
      setDetail(response);
    });
  };

  useEffect(() => {
    getDatail(id);
  }, [id]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const RenderStatus = () => {
    if (detail.isApprove) {
      return <StatusButton statusCode={APPROVED} text="APPROVED" />;
    }
    if (detail.isReject) {
      return <StatusButton statusCode={REJECTED} text="REJECTED" />;
    }
    return (
      <div style={{ display: "flex" }}>
        {isOverdue(detail.dueDate) && (
          <StatusButton statusCode={OVERDUE} text="Overdue" />
        )}
        {detail.isUrgent && <StatusButton statusCode={URGENT} text="Urgent" />}
      </div>
    );
  };

  return (
    <Scean>
      <Header>
        <MarginRight>
          <ArrowBackIcon onClick={() => navigate(-1)} />
        </MarginRight>
        <ContainerCustom className="hide-scrollbar" maxWidth="xl">
          <TitleConainer>
            {detail.prHeaderNote ? (
              <>
                <Title>{detail.prHeaderNote}</Title>
                {(detail.isApprove || detail.isReject) && (
                  <ImageStatus>
                    <img
                      alt="assets-Confirm"
                      src={
                        detail.isApprove
                          ? "/assets/Confirm.svg"
                          : "/assets/Reject.svg"
                      }
                    />
                  </ImageStatus>
                )}
              </>
            ) : (
              <Skeleton width="100%" />
            )}
          </TitleConainer>
          <RenderStatus />
        </ContainerCustom>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {MENU_BARS.map((value, index) => {
              return (
                <TabCustom key={value} label={value} {...a11yProps(index)} />
              );
            })}
          </Tabs>
        </Box>
      </Header>
      <Body>
        <CustomTabPanel value={value} index={0}>
          {headers.map(row => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}:
              </StyledTableCell>
              <StyledTableCellWidth100>
                {detail[row.key] || (!isEmpty(detail) ? "-" : <Skeleton />)}
              </StyledTableCellWidth100>
            </StyledTableRow>
          ))}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          {detail.paymentInfoItemHistory ? (
            detail.paymentInfoItemHistory.map(row => (
              <React.Fragment key={row.paymentItem}>
                <RowDetail container spacing={0}>
                  <ColDetail item xs={1}>
                    {row.paymentItem}
                  </ColDetail>
                  <ColDetail item xs={8}>
                    {row.paymentText}
                  </ColDetail>
                  <ColDetail item xs={3}>
                    ฿ {row.totalValue}
                  </ColDetail>
                </RowDetail>
                {headerDetail.map(value => (
                  <RowDetail container spacing={0}>
                    <ColDetail item xs={1} />
                    <ColDetail item xs={4}>
                      {value.name}:
                    </ColDetail>
                    <ColDetail item xs={7}>
                      {row[value.key]}
                    </ColDetail>
                  </RowDetail>
                ))}
                <Line />
              </React.Fragment>
            ))
          ) : (
            <Skeleton width="100%" />
          )}
          <SummaryMoney>
            <Grid container spacing={0}>
              <ColDetail item xs={1} />
              <ColDetail item xs={4}>
                <SummaryText>Amount (USD):</SummaryText>
              </ColDetail>
              <ColDetail item xs={7}>
                <SummaryText>$ {detail.amount}</SummaryText>
              </ColDetail>
            </Grid>
          </SummaryMoney>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <FlieTable files={detail.paymentInfoAttachHistory || []} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <Timeline position="right">
            {detail.approvers &&
              detail.approvers.map(approver => {
                approver.isReject = approver.isreject;
                return (
                  <TimelineCustom position="right">
                    <TimelineSeparator>
                      <TimelineConnector />
                      <TimelineDot
                        style={{
                          width: "44px",
                          height: "44px",
                          background: approver.isApprove
                            ? "#03703C"
                            : approver.isReject
                            ? "#D82E18"
                            : detail.waitApproveBy.userId ===
                              approver.approveBy.userId
                            ? "#174291"
                            : !approver.apprId
                            ? "#744C3A"
                            : "",
                          margin: 0,
                          padding: 0,
                          textAlign: "center",
                          justifyItems: "center",
                          justifyContent: "center"
                        }}
                      >
                        <p
                          style={{
                            fontWeight: "500",
                            fontSize: "20px",
                            textAlign: "center",
                            letterSpacing: "0.32px",
                            justifyItems: "center",
                            justifyContent: "center",
                            color: "#FFFFFF",
                            padding: 0,
                            margin: 7
                          }}
                        >
                          {approver.approveBy.fristName[0]}
                          {approver.approveBy.lastName[0]}
                        </p>
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: "12px", px: 2 }}>
                      <Grid container spacing={0}>
                        <Grid item xs={7}>
                          <NameText>{approver.approveBy.displayName}</NameText>
                          {!approver.apprId ? (
                            <Typography
                              style={{
                                position: "relative",
                                color: "#744C3A",
                                fontFamily: "FC Iconic",
                                fontStyle: "normal",
                                fontWeight: "600",
                                fontSize: "13px",
                                lineHeight: "16px",
                                letterSpacing: "1px"
                              }}
                            >
                              <SendIcon
                                style={{
                                  position: "absolute",
                                  color: "#744C3A",
                                  height: "16px",
                                  width: "16px",
                                  marginRight: "4px"
                                }}
                              />
                              <div style={{ paddingLeft: "16px" }}>
                                REQUESTED
                              </div>
                            </Typography>
                          ) : detail.waitApproveBy.userId ===
                            approver.approveBy.userId ? (
                            <Typography
                              style={{
                                color: approver.isReject
                                  ? "#D82E18"
                                  : "#174291",
                                fontFamily: "FC Iconic",
                                fontStyle: "normal",
                                fontWeight: "600",
                                fontSize: "13px",
                                lineHeight: "16px",
                                letterSpacing: "1px"
                              }}
                            >
                              {approver.isReject ? (
                                <CloseIcon
                                  style={{
                                    position: "absolute",
                                    color: "#D82E18",
                                    height: "16px",
                                    width: "16px",
                                    marginRight: "4px"
                                  }}
                                />
                              ) : (
                                <PlayCircleOutline
                                  style={{
                                    position: "absolute",
                                    color: "#174291",
                                    height: "16px",
                                    width: "16px",
                                    marginRight: "4px"
                                  }}
                                />
                              )}

                              <div style={{ paddingLeft: "16px" }}>
                                {approver.isReject ? "REJECT" : "PANDING"}
                              </div>
                            </Typography>
                          ) : (
                            <Typography
                              style={{
                                color: approver.isApprove
                                  ? "#03703C"
                                  : "#744C3A",
                                fontFamily: "FC Iconic",
                                fontStyle: "normal",
                                fontWeight: "600",
                                fontSize: "13px",
                                lineHeight: "16px",
                                letterSpacing: "1px"
                              }}
                            >
                              {approver.isReject ||
                                (approver.isApprove ? (
                                  <DoneIcon
                                    style={{
                                      position: "absolute",
                                      color: approver.isApprove
                                        ? "#03703C"
                                        : "#744C3A",
                                      height: "16px",
                                      width: "16px",
                                      marginRight: "4px"
                                    }}
                                  />
                                ) : (
                                  approver.isReject && (
                                    <CloseIcon
                                      style={{
                                        position: "absolute",
                                        color: "#D82E18",
                                        height: "16px",
                                        width: "16px",
                                        marginRight: "4px"
                                      }}
                                    />
                                  )
                                ))}

                              {approver.isReject ||
                                (approver.isApprove && (
                                  <div style={{ paddingLeft: "16px" }}>
                                    {approver.isApprove ? "APPROVED" : "REJECT"}
                                  </div>
                                ))}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={5}>
                          <Typography
                            style={{
                              textAlign: "end",
                              fontFamily: "FC Iconic",
                              fontStyle: "normal",
                              fontWeight: "normal",
                              fontSize: "15px",
                              lineHeight: "24px",
                              color: "#545454"
                            }}
                          >
                            {getFormatDayJs(approver.dueDate, "DD MMM YYYY")}
                          </Typography>
                          <Typography
                            style={{
                              textAlign: "end",
                              fontFamily: "FC Iconic",
                              fontStyle: "normal",
                              fontWeight: "normal",
                              fontSize: "15px",
                              lineHeight: "24px",
                              color: "#545454"
                            }}
                          >
                            {getFormatDayJs(approver.dueDate, "HH:mm")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </TimelineContent>
                  </TimelineCustom>
                );
              })}
          </Timeline>
        </CustomTabPanel>
      </Body>
      <FooterAppove
        waitApproveBy={detail?.waitApproveBy}
        userCancelApproved={detail?.userCancelApproved}
        amount={detail?.amount}
        titlePayment={detail?.prHeaderNote}
        id={detail?.payId}
        getDatail={getDatail}
        isApprove={detail?.isApprove}
        isReject={detail?.isReject}
      />
    </Scean>
  );
}

const Line = styled("hr")({
  marginRight: "16px",
  marginLeft: "16px"
});

const SummaryText = styled("p")({
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "15px",
  lineHeight: "20px",
  letterSpacing: "0.48px",
  fontFeatureSettings: "'ss03' on, 'ss04' on, 'ss01' on, 'case' on",
  color: "#FFFFFF",
  padding: 0,
  margin: 0
});

const SummaryMoney = styled("div")({
  width: "100%",
  background: "#545454"
});

const StyledTableCellWidth100 = styled(StyledTableCell)({
  width: "100%"
});

const TitleConainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start"
});

const ContainerCustom = styled(Container)({
  width: "100%",
  margin: "14px 0px",
  overflowX: "scroll"
});
const Scean = styled("div")({
  height: "100%"
});

const MarginRight = styled("div")({
  margin: "20px"
});

const Header = styled("div")({
  position: "fixed",
  width: "100%"
});

const Body = styled("div")({
  position: "fixed",
  top: "180px",
  width: "100%",
  overflow: "auto",
  height: "calc(100% - 280px)"
});

const Title = styled("p")({
  fontFamily: "FC Iconic",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "25px",
  // lineHeight: "36px",
  letterSpacing: "0.48px",
  color: "#141414",
  margin: "0px"
});

const TimelineCustom = styled(TimelineItem)({
  "&:before": {
    content: "none",
    flex: 1,
    padding: "0px"
  }
});

const NameText = styled(Typography)({
  letterSpacing: "0.48px",
  color: "#141414",
  fontFamily: "FC Iconic",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "15px",
  lineHeight: "20px"
});

const RowDetail = styled(Grid)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  },
  margin: "0px",
  padding: "16px"
}));

const ColDetail = styled(Grid)(({ theme }) => ({
  padding: "0px",
  paddingLeft: "0px",
  paddingRight: "0px"
}));

const TabCustom = styled(Tab)({
  textTransform: "none"
});
const isOverdue = date => {
  const dueDate = dayjs(date);
  const toDay = dayjs();
  const diff = dueDate.diff(toDay);
  return moreZero(diff);
};

const ImageStatus = styled("div")({
  position: "absolute",
  right: 16,
  top: 24
});

export default DetailPage;
