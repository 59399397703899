import Button from "@mui/material/Button";
import styled from "@mui/material/styles/styled";
import { DEFAULT } from "../../utils";
import buttonThemes from "../../themes/buttons";

const ButtonConfirm = styled(Button)(({ type }) => ({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  borderRadius: "8px",
  lineHeight: 1.5,
  backgroundColor: buttonThemes[type].backgroundColor, //"#545454", //"#0063cc",
  borderColor: "#0063cc",
  border: `1px solid ${buttonThemes[type].borderColor}`,
  color: buttonThemes[type].textColor,
  width: "100%",
  "&:hover": {
    backgroundColor: "#545454"
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)"
  }
}));
ButtonConfirm.defaultProps = {
  type: DEFAULT
};
export default ButtonConfirm;
