import callApi from "../../lib/fetch";
import { getFormatDayJs } from "../../utils/format/date";
import cookie from "js-cookie";

const getPaymentHistorys = filter => {
  return new Promise((resolve, reject) => {
    callApi
      .post("/approval/paymentlist/history", filter)
      .then(response => {
        resolve(response.data.responseData);
      })
      .catch(error => {
        reject(error);
      })
      .finally(() => {});
  });
};

const getPaymentHistory = id => {
  const userId = cookie.get("userId");
  return new Promise((resolve, reject) => {
    callApi
      .get(`/approval/paymentlist/history/${id}`)
      .then(response => {
        response = response.data.responseData[0];
        const approver = response.approvers.find(
          value => value.approveBy.userId.toString() === userId
        );
        if (approver.isApprove) {
          response.isApprove = true;
        }
        // TODO: waite api rename.
        if (approver.isreject) {
          response.isReject = true;
        }
        if (approver.isReject) {
          response.isReject = true;
        }

        let indexUserCancelApproved = 0;
        response.approvers.find((approver, index) => {
          if (approver.isreject) {
            indexUserCancelApproved = index;
            return true;
          }
          indexUserCancelApproved = index - 1;
          return (
            approver?.approveBy?.userId === response?.waitApproveBy?.userId
          );
        });
        if (indexUserCancelApproved < 0) {
          indexUserCancelApproved = 0;
        }

        response.userCancelApproved =
          response?.approvers[indexUserCancelApproved]?.approveBy;
        response.approvers.unshift({ approveBy: response.sendApproveBy });
        response.dueDateFormat = getFormatDayJs(response.dueDate, "DD.MM.YYYY");
        response.postingDateFormat = getFormatDayJs(
          response.postingDate,
          "DD.MM.YYYY"
        );
        response.documentDateFormat = getFormatDayJs(
          response.documentDate,
          "DD.MM.YYYY"
        );
        console.log(response);
        resolve(response);
      })
      .catch(error => {
        reject(error);
      })
      .finally(() => {});
  });
};

const sendApproversPaymentHistory = body => {
  return new Promise((resolve, reject) => {
    callApi
      .post(`/approval/paymentlist/history/approved`, body)
      .then(response => {
        resolve(response.data.responseData[0]);
      })
      .catch(error => {
        reject(error);
      })
      .finally(() => {});
  });
};

const rejectPaymentHistory = body => {
  return new Promise((resolve, reject) => {
    callApi
      .post(`/approval/paymentlist/history/rejected`, body)
      .then(response => {
        resolve(response.data.responseData[0]);
      })
      .catch(error => {
        reject(error);
      })
      .finally(() => {});
  });
};

const getPaymentHistoryLogs = body => {
  return new Promise((resolve, reject) => {
    callApi
      .post("/approval/paymentlist/history/log", body)
      .then(response => {
        console.log(response.data.responseData);
        resolve(response.data.responseData);
      })
      .catch(error => {
        reject(error);
      })
      .finally(() => {});
  });
};

export {
  getPaymentHistorys,
  getPaymentHistory,
  sendApproversPaymentHistory,
  rejectPaymentHistory,
  getPaymentHistoryLogs
};
