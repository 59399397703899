import {
  DEFAULT,
  RESET,
  ENABLED,
  REJECT_BUTTON,
  APPROVE_BUTTON,
  DISABLE,
  CANCLE_APPROVED
} from "../utils/index";

const buttonThemes = {
  [DEFAULT]: {
    backgroundColor: "#545454",
    borderColor: "#0063cc",
    hoverColor: "#545454",
    textColor: "#FFFFFF"
  },
  [DEFAULT]: {
    backgroundColor: "#545454",
    borderColor: "#0063cc",
    hoverColor: "#545454",
    textColor: "#FFFFFF"
  },
  [RESET]: {
    backgroundColor: "#FFFFFF",
    borderColor: "#AFAFAF",
    hoverColor: "#FFFFFF",
    textColor: "#141414"
  },
  [ENABLED]: {
    backgroundColor: "#174291",
    borderColor: "#174291",
    hoverColor: "#174291",
    textColor: "#FFFFFF"
  },
  [REJECT_BUTTON]: {
    backgroundColor: "#D82E18",
    borderColor: "#D82E18",
    hoverColor: "#D82E18",
    textColor: "#FFFFFF"
  },
  [APPROVE_BUTTON]: {
    backgroundColor: "#03703C",
    borderColor: "#03703C",
    hoverColor: "#03703C",
    textColor: "#FFFFFF"
  },
  [DISABLE]: {
    backgroundColor: "#AFAFAF",
    borderColor: "#AFAFAF",
    hoverColor: "#AFAFAF",
    textColor: "#FFFFFF"
  },
  [CANCLE_APPROVED]: {
    backgroundColor: "#C14F29",
    borderColor: "#C14F29",
    hoverColor: "#C14F29",
    textColor: "#FFFFFF"
  }
};

export default buttonThemes;
