import { useEffect, useState } from "react";
import DashBordCard from "../components/Card/DashBordCard";
import FlakyIcon from "@mui/icons-material/Flaky";
import HistoryIcon from "@mui/icons-material/History";
import styled from "@mui/material/styles/styled";
import Navbar from "../components/Navbar";
import { OVERDUE, URGENT, APPROVED, REJECTED } from "../utils";
import { getDashbord } from "../api/dashbord";
import Footer from "../components/Footer";

function HomePage() {
  const [summary, setSummary] = useState({});

  useEffect(() => {
    getDashbord().then(response => {
      setSummary(response);
    });
  }, []);

  return (
    <div>
      <Navbar />
      <Body>
        <DashBordCard
          title="Approval"
          subTitle="Number of pending approval"
          amount={
            summary?.waitApproveDocument ? summary?.waitApproveDocument : 0
          }
          iconCardBase={
            <FlakyIcon style={{ height: "106px", width: "106px" }} />
          }
          buttons={[
            {
              statusCode: OVERDUE,
              text: "OVERDUE",
              amount: summary?.isOverDue ? summary?.isOverDue : 0
            },
            {
              statusCode: URGENT,
              text: "URGENT",
              amount: summary?.isUrgent ? summary?.isUrgent : 0
            }
          ]}
        />
        <DashBordCard
          title="History"
          subTitle="Number of approved"
          amount={summary?.allHistoryDocument ? summary?.allHistoryDocument : 0}
          iconCardBase={
            <HistoryIcon style={{ height: "106px", width: "106px" }} />
          }
          buttons={[
            {
              statusCode: APPROVED,
              text: "APPROVED",
              amount: summary?.approvedDocument ? summary?.approvedDocument : 0
            },
            {
              statusCode: REJECTED,
              text: "REJECTED",
              amount: summary?.rejectedDocument ? summary?.rejectedDocument : 0
            }
          ]}
        />
        <div style={{ height: "60px" }} />
      </Body>
      <Footer />
    </div>
  );
}

const Body = styled("div")({
  height: "calc(100% - 87px)",
  overflow: "auto",
  padding: "16px"
});

export default HomePage;
